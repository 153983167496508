import React, { useContext, useEffect, useState } from 'react';
import './Header.scss';
import { Link, NavLink } from 'react-router-dom';
import { BasicButton } from '../button';
import { HandshakeOutlined, Menu } from '@mui/icons-material';
import { LinearProgress, useMediaQuery } from '@mui/material';
import { Modal, useModalManager } from '../modal';
import HeaderMenuModal from '../modal/dist/HeaderMenuModal';
import { ColorsContext } from '../../context/Colors';
import navigation from './navigation.json';

const NavHeaderLink = ({ to, title }) => {
    const [ isActive, setIsActive ] = useState(false);
    const [ classNamer, setClassNamer ] = useState('');
    
    useEffect(() => {
        if (isActive) {
            setClassNamer('active');
            setTimeout(() => {
                setClassNamer('active removeLineAnimation');
                setTimeout(() => {
                    setClassNamer('active removeLine');
                    setTimeout(() => {
                        setClassNamer('active removeLineTransition')
                    }, 100);
                }, 100)
            }, 500)
        } else {
            setClassNamer('');
        }
    }, [isActive])

    return (
        <NavLink
            to={to}
            className={({ isActive }) => {
                setIsActive(isActive);
                return `nav-link-click ${classNamer}`;
              }}
        >
            {title}
        </NavLink>
    )
}

export default function Header() {
    const [ scrolled, setScrolled ] = useState(false);
    const { updating } = useContext(ColorsContext);
    const { openModal, closeModal } = useModalManager();

    const match = useMediaQuery('(max-width: 960px)');

    const handleSidebar = () => {
        openModal('sidebar', (
            <Modal
                close={() => closeModal('sidebar')}
            >
                <HeaderMenuModal />
            </Modal>
        ))
    }

    useEffect(() => {
        const handleScroll = () => {
            console.log('xd');
            if (window.scrollY > 16) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    console.log(scrolled)

    return (
        <header className='main-header'>
            <div className={`header-upper ${scrolled ? 'scrolled' : ''}`}>
                <div className="container">
                    <div className="header-inner flex align-center">
                        <div className="logo-outer">
                            <div className="logo">
                                <Link to="/">
                                    AndresCortesDev
                                </Link>
                            </div>
                        </div>
                        <div className="nav-outer">
                            <nav className="main-menu">
                                <div className="navbar-header">

                                </div>
                                <div className="navbar-collapse">
                                    <ul className='navigation'>
                                        {navigation.map((nav, i) => (
                                            <li key={i}>
                                                <NavHeaderLink to={nav.to} title={nav.title} />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div className="menu-btns gap-10 flex">
                            <BasicButton 
                                variant='outlined'
                                className='hider'
                                title='Hire Me'
                                rightIcon={<HandshakeOutlined />}
                            />
                            {match &&
                                <BasicButton 
                                    onClick={handleSidebar}
                                    icon={<Menu />}
                                />
                            }
                        </div>
                    </div>
                </div>
                {updating && <LinearProgress className='header-progress' />}
            </div>
        </header>
    )
}
