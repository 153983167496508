import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { WorksByCategory } from '../../components/sections/Works';

export default function W() {
    const params = useParams();

    return (
        <div
            style={{
                paddingTop: '7rem'
            }}
        >
            <WorksByCategory/>
        </div>
    )
}
