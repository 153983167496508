import React from 'react'

export default function LogoSVG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 1912.000000 2059.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,2059.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M12216 18265 c-2955 -1711 -3983 -2302 -3995 -2295 -9 4 -1205 697 -2659 1538 l-2643 1529 -1454 -841 -1455 -841 0 -6296 0 -6295 1571 -910 c864 -500 1575 -910 1580 -912 5 -2 10 642 11 1532 l3 1535 1075 -622 1075 -622 3 -1540 2 -1540 1443 -835 c793 -459 1451 -835 1462 -835 11 0 2465 1415 5453 3144 l5432 3144 -2 1681 -3 1681 -1445 837 c-795 460 -1450 837 -1456 837 -6 1 -549 -309 -1205 -689 -656 -380 -1197 -690 -1201 -690 -5 0 -8 561 -8 1248 l0 1247 2660 1540 2660 1539 0 1681 -1 1680 -1444 835 c-794 459 -1452 836 -1462 837 -10 1 -1809 -1035 -3997 -2302z m5082 1110 c808 -468 1068 -623 1059 -631 -7 -6 -1675 -973 -3707 -2149 -2032 -1175 -3708 -2145 -3725 -2155 l-30 -18 -1074 621 c-591 342 -1074 627 -1074 632 0 14 7444 4324 7464 4322 9 -1 498 -281 1087 -622z m-10642 -3072 c2050 -1187 3727 -2162 3726 -2168 0 -5 -484 -289 -1074 -631 l-1073 -621 -55 33 c-30 17 -1709 989 -3730 2159 -2021 1169 -3678 2130 -3683 2134 -7 7 2127 1250 2149 1251 6 0 1689 -971 3740 -2157z m11964 765 l0 -1253 -2655 -1535 -2654 -1535 -1 -3362 0 -3362 83 48 c45 27 1236 716 2647 1531 1411 815 2568 1484 2573 1487 4 2 7 -560 7 -1249 l0 -1253 -3738 -2163 -3737 -2163 -3 5863 c-2 4681 1 5865 10 5874 11 10 7457 4323 7466 4324 1 0 2 -564 2 -1252z m-14346 -2463 l3715 -2150 1 -5868 c0 -3228 -3 -5866 -7 -5864 -5 3 -485 280 -1068 617 -583 337 -1068 620 -1077 628 -17 14 -18 103 -20 1550 l-3 1536 -1569 908 c-863 499 -1571 908 -1573 908 -1 0 -4 -691 -5 -1535 l-3 -1535 -1077 622 -1077 623 -1 5868 0 5868 24 -13 c13 -7 1696 -980 3740 -2163z m6376 -6769 l0 -5863 -1077 -623 c-593 -343 -1081 -625 -1085 -627 -5 -2 -8 2636 -8 5864 l1 5868 1072 621 c589 342 1078 622 1085 623 9 1 12 -1178 12 -5863z m6652 2306 c587 -339 1067 -619 1067 -622 0 -4 -4538 -2629 -4561 -2638 -4 -2 -7 560 -6 1249 l3 1252 1200 694 c724 418 1206 691 1215 688 8 -4 495 -284 1082 -623z"/>
        <path d="M2672 11198 l3 -1823 1568 -907 c863 -500 1571 -908 1573 -908 2 0 4 820 4 1823 l0 1822 -1568 908 c-863 499 -1572 907 -1575 907 -4 0 -6 -820 -5 -1822z m1458 413 c514 -298 938 -545 942 -549 3 -4 -423 -255 -948 -558 l-954 -552 0 1105 c0 880 3 1104 13 1100 6 -3 433 -249 947 -546z m1198 -2644 l-3 -553 -952 551 c-524 302 -952 552 -953 555 0 3 429 254 953 557 l952 551 3 -554 c1 -305 1 -803 0 -1107z"/>
        </g>
    </svg>
  )
}
