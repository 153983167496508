import React from 'react'
import { LetsTalk, WorksSection } from '../../components/sections'

export default function Works() {
    return (
        <>
            <WorksSection className='first-area' />
            <LetsTalk />
        </>
    )
}
