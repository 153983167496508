import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css';
import { Navigation } from 'swiper/modules';
import { BasicButton } from '../button';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import LightCard from '../cards/LightCard';

const TestimonialCard = () => {

    return (
        <LightCard>
            <div className='testimonial-header'>
                <img src="https://bentos-react.vercel.app/assets/author1-R1o5kqXP.jpg" alt="" />
            </div>
            <div className='testimonial-body'>
                <p>Financial planners help people gain knowledge about how to invest and save their money efficiently.</p>
                <div className="testimonial-author">
                    <h4>Zonathon Doe</h4>
                    <span>CEO & Founder X</span>
                </div>
            </div>
        </LightCard>
    );
};

export default function TestimonialSwiper() {
    const match = useMediaQuery('(max-width: 680px)');
    return (
        <div className='margin-top-20'>
            <Swiper
                slidesPerView={match ? 1 : 2}
                spaceBetween={32}
                navigation={{
                    nextEl: '.button-next',
                    prevEl: '.button-prev',
                    disabledClass: 'disabled',
                    clickable: true
                }}
                modules={[Navigation]}
            >
                <SwiperSlide><TestimonialCard /></SwiperSlide>
                <SwiperSlide><TestimonialCard /></SwiperSlide>
                <SwiperSlide><TestimonialCard /></SwiperSlide>
                <SwiperSlide><TestimonialCard /></SwiperSlide>
            </Swiper>
            <div className="swiper-navigation">
                <BasicButton 
                    className='button-prev rounded'
                    icon={<ArrowForwardIos style={{ transform: 'rotate(180deg)'}} />}
                />
                <BasicButton 
                    className='button-next rounded'
                    icon={<ArrowForwardIos />}
                />
            </div>
        </div>
    )
}
