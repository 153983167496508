import React from 'react'
import { LazyImage } from '../lazy'
import LogoSVG from '../../assets/images/logo'
import { BasicButton } from '../button'
import { FacebookRounded, GitHub, LinkedIn, X } from '@mui/icons-material'
import { Link } from 'react-router-dom'

export default function AboutImage({ moreInfo }) {
  return (
    <div className='about-image'>
      <div className='home-section color-base'>
          <LazyImage 
              aspectRatio={1/1}
              src={<LogoSVG />}
              isSvg
              borderRadius={15}
              className='margin-bottom-10'
          />
          {moreInfo &&
            <>
              <h2>AndresCortesDev</h2>
              <p>Programador y Profesor en Colombia</p>
              <div className='margin-top-10'>
                  <ul className='flex align-center justify-center flow-row gap-5' style={{ listStyle: 'none'}}>
                      <li>
                          <BasicButton 
                              icon={<FacebookRounded />}
                              component={Link}
                              variant='outlined'
                          />
                      </li>
                      <li>
                          <BasicButton 
                              icon={<X />}
                              component={Link}
                              variant='outlined'
                          />
                      </li>
                      <li>
                          <BasicButton 
                              icon={<LinkedIn />}
                              component={Link}
                              variant='outlined'
                          />
                      </li>
                      <li>
                          <BasicButton 
                              icon={<GitHub />}
                              component={Link}
                              variant='outlined'
                          />
                      </li>
                  </ul>
              </div>
            </>
          }
      </div>
  </div>
  )
}
