import { createBrowserRouter } from "react-router-dom";
import { Layout } from ".";
import { Home } from "./home";
import { About } from "./about";
import { Service } from "./service";
import { Work, Works } from "./works";
import { Contact } from "./contact";
import W from "./w/W";

export const PageRouter = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path: "about",
                element: <About />
            },
            {
                path: "service",
                element: <Service />
            },
            {
                path: "w",
                children: [
                    {
                        path: ":language",
                        element: <W />
                    }
                ]
            },
            {
                path: "works",
                element: <Works />,
            },
            {
                path: "works/:id",
                element: <Work />,
            },
            {
                path: "contact",
                element: <Contact />
            }
        ]
    }
])