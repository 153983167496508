import React, { useState } from 'react'
import { Section } from '../../components'
import LightCard from '../../components/cards/LightCard';
import { AddShoppingCartRounded, CheckRounded, Draw, Language, Layers } from '@mui/icons-material';
import { BasicButton } from '../../components/button';
import { LetsTalk } from '../../components/sections';

export default function Service() {
    return (
        <>
            <Section id="services" className={'services-area'}>
                <div className="container">
                    <div className="home-section color-base">
                        <div className="section-title align-center">
                            <p>Services</p>
                            <h2>Quality Services</h2>
                        </div>
                        <div className='service-grid'>
                            <LightCard>
                                <div className='service-body'>
                                    <Language />
                                    <h2>Brand Identity Design</h2>
                                    <p>Bentos gives you the blocks & kits you need to create a true website within minutes.</p>
                                </div>
                            </LightCard>
                            <LightCard>
                                <div className='service-body'>
                                    <Draw />
                                    <h2>Website Design</h2>
                                    <p>Bentos gives you the blocks & kits you need to create a true website within minutes.</p>
                                </div>
                            </LightCard>
                            <LightCard>
                                <div className='service-body'>
                                    <Layers />
                                    <h2>Application Design</h2>
                                    <p>Bentos gives you the blocks & kits you need to create a true website within minutes.</p>
                                </div>
                            </LightCard>
                        </div>
                    </div>
                </div>
            </Section>
            <Section id="pricing" className={'pricing-area'}>
                <div className="container">
                    <div className="home-section color-base">
                        <div className="section-title align-center">
                            <p>Pricing</p>
                            <h2>Flexible Pricing Plan</h2>
                        </div>
                        <div className='pricing-grid'>
                            <div className='pricing-card'>
                                <div className="pricing-header">
                                    <h3>Basic</h3>
                                    <p>Have design ready to build? or small budget</p>
                                    <span>
                                        <span className='price'>$15</span>
                                        <span className='timer'>/Hour</span>
                                    </span>
                                </div>
                                <div className="pricing-body">
                                    <ul>
                                        <li>
                                            <span><CheckRounded /></span>
                                            <span>Need your wireframe</span>
                                        </li>
                                        <li>
                                            <span><CheckRounded /></span>
                                            <span>Design with Figma, Framer</span>
                                        </li>
                                        <li>
                                            <span><CheckRounded /></span>
                                            <span>Product Design</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="pricing-footer">
                                    <BasicButton 
                                        title='Order Now'
                                        rightIcon={<AddShoppingCartRounded />}
                                    />
                                </div>
                            </div>
                            <div className='pricing-card'>
                                <div className="pricing-header">
                                    <h3>Basic</h3>
                                    <p>Have design ready to build? or small budget</p>
                                    <span>
                                        <span className='price'>$15</span>
                                        <span className='timer'>/Hour</span>
                                    </span>
                                </div>
                                <div className="pricing-body">
                                    <ul>
                                        <li>
                                            <span><CheckRounded /></span>
                                            <span>Need your wireframe</span>
                                        </li>
                                        <li>
                                            <span><CheckRounded /></span>
                                            <span>Design with Figma, Framer</span>
                                        </li>
                                        <li>
                                            <span><CheckRounded /></span>
                                            <span>Product Design</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="pricing-footer">
                                    <BasicButton 
                                        title='Order Now'
                                        rightIcon={<AddShoppingCartRounded />}
                                    />
                                </div>
                            </div>
                            <div className='pricing-card'>
                                <div className="pricing-header">
                                    <h3>Basic</h3>
                                    <p>Have design ready to build? or small budget</p>
                                    <span>
                                        <span className='price'>$15</span>
                                        <span className='timer'>/Hour</span>
                                    </span>
                                </div>
                                <div className="pricing-body">
                                    <ul>
                                        <li>
                                            <span><CheckRounded /></span>
                                            <span>Need your wireframe</span>
                                        </li>
                                        <li>
                                            <span><CheckRounded /></span>
                                            <span>Design with Figma, Framer</span>
                                        </li>
                                        <li>
                                            <span><CheckRounded /></span>
                                            <span>Product Design</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="pricing-footer">
                                    <BasicButton 
                                        title='Order Now'
                                        rightIcon={<AddShoppingCartRounded />}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
            <LetsTalk />
        </>   
    )
}
